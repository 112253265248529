import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
  faMinus,
  faTimes,
  faCheck,
  faArrowLeft,
  faDownload,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import GridViewIcon from "../../../../constants/icons/gridview.svg";
import BarsIcon from "../../../../constants/icons/bars.svg";
import UserImg from "../../../../constants/icons/user-img.svg";
import EditIcon from "../../../../constants/icons/edit.svg";

import ModalImport from "../../../../helpers/ModalImport";

import { getNameFromCustomForm } from "../../Grades/utils";

const EventAttendeeListStyled = styled.div`

    padding: 15px;
    background-color: white;
    

    #event-attendee-table {
        width: 100%;
        border-collapse: collapse;
    }

    .event-attendee-header {
        background-color: #FC9A3B;
        color: #1B0B03;
        height 40px;
        padding: 12px !important;
        padding: 15px;
    }

      
    .event-attendee-header th:nth-child(1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-align: left;
    }
      
    .event-attendee-header th:nth-child(6) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    #event-attendee-table tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

      
    #event-attendee-table tr:nth-child(even){
        background-color: #f4f4f5;
    }
 
    #event-attendee-table tr:nth-child(odd) {
        background-color: white;
    }


    #event-attendee-table tr td:not(:first-child) {
        text-align: center;
    }

    #event-attendee-table td,
    #event-attendee-table th {
        border: 0;
        padding: 15px;
    }

    #event-attendee-table th {
        padding-left: 15px;
    }

    .attendance-icon {
        width: 30px;
        height: 30px;
        border: 1px solid #6C6C6C;
        background-color: #f4f4f5;
        margin: 0 auto;
        border-radius: 50%;
        padding-top: 1.5px;
    }

    .event-input {
        padding: 8px 16px;
        width: 40px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    .attendance-input-hours {
        padding: 8px 16px;
        width: 25px;
        align-items: center;
        text-align: center;
        border: 1px solid #cacacb;
        height: 14.4px;
    }

    .attendance-input-btn {
        position: relative;
        top: 2px;
        background-color: white;
        border: 1px solid #cacacb;
        margin-top: 5px;
        height: 32.8px;
    }
    .attendance-input-dec {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 0px;
    }

    .attendance-input-inc {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: 0px;
    }

    .attendance-present { 
        background-color: #F0F9EE;
        border: 1px solid #3B7D2A;
        color: #3B7D2A;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-late { 
        background-color: #FFE3CF;
        border: 1px solid #EF8722;
        color: #EF8722;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-absent { 
        background-color: #FDE6E6;
        border: 1px solid #EB0000;
        color: #EB0000;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-present > svg,
    .attendance-late > svg,
    .attendance-absent > svg {
        margin: 0 auto;
    }
    
    .attendance-excused { 
        font-size: 12px;
        height: 15px;
        margin: 0 auto;
        color: #6C6C6C;
    }

    .attendance-present-container {
        position: relative;
        top: 0px;
    }
    
    
    .link-text {
        color: #18A0FB !important;
        text-decoration: none;
    }

    .empty-name {
        width: 70px;
        text-align: center;
    }

    .btn-filter { 
        background-color: white;
        border: 1.8px solid #CACACB;
        border-radius: 8px;
        margin-left: 12px;
        width: 120px;

    }
 
    .attendee-header-action {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 12px;
        margin-top: 12px;
        margin-bottom: 12px;
	}
	.attendee-header-action > div > select {
		border: 1.8px solid #CACACB;
		border-radius: 8px;
		width: 90px;
		height: 37px;
	}

	.attendee-header-action > div > button {
		background-color: white;
		border: 1.8px solid #CACACB;
		border-radius: 8px;
		margin-left: 12px;
		width: 120px;
	}
	.btn-view-toggle-container button {
		background-color: white;
		border: 1px solid #dedede;
	}

	.btn-view-toggle-container button:nth-child(1)  {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
   }
  
   .btn-view-toggle-container button:nth-child(2)  {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

    .view-toggle-selected {
		background-color: #FC9A3B !important;
	}

    
    .user-details {
        text-align: left;
        position: relative;
        top: 8px;
        left: 12px;
    }

    .user-id { 
        color: #6C6C6C;
    }

    
    .input-pagination {
        padding: 8px 16px;
        align-items: center;
        border: 1.8px solid #dedede;
        border-radius: 8px !important;
    }
  
    .btn-view-page-container {
        background-color: white;
        border: 2px solid #dedede;
        border-radius: 12px;
        overflow: hidden;
      
      }
      
    .btn-view-page-container button {
        background-color: white !important;
    }

    .event-view-input {
        margin-left: 4px;
    }
`;

const PAGINATION = [10, 20, 30];

const STUDENT_DATA = [
  {
    name: "Alex English",
    attendance_status: "Present",
  },
  {
    name: "Nina Brown",
    attendance_status: "Tardy",
  },
  {
    name: "Luis Chavez",
    attendance_status: "Absent",
  },
  {
    name: "Sandra Lee",
    attendance_status: "Present",
  },
  {
    name: "Mark Miller",
    attendance_status: "Absent",
  },
  {
    name: "Zuko Lee",
    attendance_status: "Tardy",
  },
];

// LIGHT GREEN - 66BD50
// GREEN - 3B7D2A
// LIGHT ORANGE - FFE3CF
// DARK ORANGE - EF8722
// ERROR - EB0000
// LIGHT RED - FDE6E6
const EventAttendeeList = ({ data = [], 
  handleAttendance, 
  handleHours, 
  handleSetAttendance, 
  handleSave, 
  setIsEditMode, 
  vendorId 
}) => {

  const [openImportModal, setOpenImportModal] = useState(false);
  const [viewMode, setViewMode] = useState("list");
  const [pagination, setPagination] = useState({
    page: 1,
    offset: 0,
    limit: 10,
    total: 0,
  });
  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
    let initialList = [...data];
    initialList = initialList.splice(0, 10);
    setAttendees([...initialList]);

    setPagination((prevState) => {
      return {
        ...prevState,
        total: Math.ceil(data.length / prevState.limit),
      };
    });
  }, [data]);

  const handlePageLimit = (e) => {
    const value = parseInt(e.target.value);
    // const targetEvents = [];
    // const updatedEventList = targetEvents.splice(0, value);

    let displayedList = [...data];
    displayedList = displayedList.splice(0, value);

    setAttendees([...displayedList]);

    setPagination((prevState) => {
      return {
        ...prevState,
        page: 1,
        total: Math.ceil(data.length / value),
        limit: value,
      };
    });
  };

  const handlePagination = (type) => () => {
    let currentAttendeeList = [...data];

    let currentPagination = {
      ...pagination,
    };
    if (type === "next") {
      currentPagination.offset =
        currentPagination.offset + currentPagination.limit;
      currentAttendeeList = currentAttendeeList.splice(
        currentPagination.offset,
        currentPagination.limit
      );

      setAttendees([...(currentAttendeeList || [])]);
      setPagination({
        ...currentPagination,
        page: currentPagination.page + 1,
      });
      setEvents([...(currentEventList || [])]);
    } else if (type === "prev") {
      currentPagination.offset =
        currentPagination.offset - currentPagination.limit;
      currentAttendeeList = currentAttendeeList.splice(
        currentPagination.offset,
        currentPagination.limit
      );

      setAttendees([...(currentAttendeeList || [])]);
      setPagination({
        ...currentPagination,
        page: currentPagination.page - 1,
      });
    }
  };

  const handleImportAttendanceData = (data) => {
    let updatedFilteredApplication = [...(attendees || [])];
    updatedFilteredApplication = updatedFilteredApplication.map((item) => {

      const currentRecord = data.find(
        (item2) => item2.unique_id === item?.new_childId
      );
      if (currentRecord) {

        const payload = {
          ...item,
          attendance_status: currentRecord.attendance_status,
          mentoring_hours: parseInt(currentRecord.mentoring_hours || 0),
          volunteer_hours: parseInt(currentRecord.volunteer_hours || 0),
        };
        return {
          ...payload,
        };
      }

      return {
        ...item,
      };
    });
    setAttendees(updatedFilteredApplication);

    if (typeof handleSetAttendance === 'function') {
      handleSetAttendance(updatedFilteredApplication)
    }

  };

  return (
    <EventAttendeeListStyled>
      <div className="attendee-header-action">
        <div>
          {/* <select className="event-input" placeholder="Day 1">
            <option>Day 1</option>
          </select>
          <button onClick={() => {
            setOpenImportModal(true);
          }} className="btn-filter" type="button">
            <FontAwesomeIcon icon={faDownload} />
            {`  `}
            Import CSV
          </button> */}
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <input
            className="event-input search-input"
            type="text"
            placeholder="Search"
          /> */}
          {/* <select className="event-input event-view-input">
            <option>View</option>
          </select> */}
          <div
            className="btn-view-toggle-container"
            style={{ display: "flex", marginLeft: 4 }}
          >
            {/* <button
              type="button"
              className={`btn-toggle ${viewMode === "grid" && "view-toggle-selected"
                }`}
              onClick={() => {
                setViewMode("grid");
              }}
            >
              <img
                src={GridViewIcon}
                alt="grid"
                style={{ width: 14, height: 14 }}
              />
            </button> */}
            {/* <button
              type="button"
              className={`btn-toggle ${viewMode === "list" && "view-toggle-selected"
                }`}
              onClick={() => {
                setViewMode("list");
              }}
            >
              <img
                src={BarsIcon}
                alt="grid"
                style={{ width: 14, height: 14 }}
              />
            </button> */}
          </div>
          {/* <button
            style={{ backgroundColor: "white", width: 50 }}
            type="button"
            onClick={() => {
              setIsEditMode((prevState) => !prevState);
            }}
            className="input-filter"
          >
            <img
              src={EditIcon}
              alt="edit"

              style={{ width: 12, height: 12 }}
            />
          </button> */}
        </div>
      </div>

      <table id="event-attendee-table">
        <thead className="event-attendee-header">
          <th>Name</th>
          <th>Present</th>
          <th>Late</th>
          <th>Absent</th>
          <th>Volunteer Hrs</th>
          <th>Mentoring Hrs</th>
        </thead>

        <tbody>
          {attendees.map((item) => {
            const currentChild = item?.form_contents
              ? getNameFromCustomForm(item.form_contents)
              : {
                lastname: item?.lastname || "",
                firstname: item?.firstname || "",
              };

            return (
              <tr>
                <td style={{ textAlign: "left" }}>
                  {currentChild?.firstname && currentChild?.lastname ? (
                    <a
                      className="link-text"
                      href={`/dashboard/menteeprofile/${item.id}`}
                    >
                      {currentChild.firstname} {currentChild.lastname}
                    </a>
                  ) : (
                    <div className="empty-name">--</div>
                  )}
                </td>
                <td style={{ width: 120, paddingTop: 0 }}>
                  <div
                    onClick={() => {
                      handleAttendance(item, "Present");
                    }}
                    onDoubleClick ={() => {
                      handleAttendance(item, "false");
                    }}
                    className={`attendance-icon ${item.attendance_status === "Present" &&
                      "attendance-present"
                      }`}
                  >
                    {item.attendance_status === "Present" && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  </div>
                </td>
                <td style={{ width: 120 }}>
                  <div
                    onClick={() => {
                      handleAttendance(item, "Tardy");
                    }}
                    onDoubleClick ={() => {
                      handleAttendance(item, "false");
                    }}
                    className={`attendance-icon ${item.attendance_status === "Tardy" && "attendance-late"
                      }`}
                  >
                    {item.attendance_status === "Tardy" && (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </div>
                  <div className="attendance-excused">
                    {item?.excused === "tardy" ? "Excused" : ""}
                  </div>
                </td>
                <td style={{ width: 120 }}>
                  <div
                    onClick={() => {
                      handleAttendance(item, "Absent");
                    }}
                    onDoubleClick ={() => {
                      handleAttendance(item, "false");
                    }}
                    className={`attendance-icon ${item.attendance_status === "Absent" && "attendance-absent"
                      }`}
                  >
                    {item.attendance_status === "Absent" && (
                      <FontAwesomeIcon icon={faTimes} />
                    )}
                  </div>
                  <div className="attendance-excused">
                    {item?.excused === "absent" ? "Excused" : ""}
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      onClick={() => {
                        let volunteerHrs = item?.volunteer_hours || 0;
                        volunteerHrs = volunteerHrs - 1;
                        if (volunteerHrs > -1) {
                          handleHours(item, volunteerHrs, "volunteer_hours");
                        }
                      }}
                      type="button"
                      className="attendance-input-btn attendance-input-dec"
                    >
                      -
                    </button>
                    <input
                      onChange={(e) => {
                        handleHours(item, e.target.value, "volunteer_hours");
                      }}
                      value={item?.volunteer_hours || 0}
                      className="attendance-input-hours"
                      type="number"
                    />
                    <button
                      onClick={() => {
                        let volunteerHrs = item?.volunteer_hours || 0;
                        volunteerHrs = volunteerHrs + 1;
                        if (volunteerHrs > -1) {
                          handleHours(item, volunteerHrs, "volunteer_hours");
                        }
                      }}
                      type="button"
                      className="attendance-input-btn attendance-input-inc"
                    >
                      +
                    </button>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      onClick={() => {
                        let mentoringHrs = item?.mentoring_hours || 0;
                        mentoringHrs = mentoringHrs - 1;
                        if (mentoringHrs > -1) {
                          handleHours(item, mentoringHrs, "mentoring_hours");
                        }
                      }}
                      type="button"
                      className="attendance-input-btn attendance-input-dec"
                    >
                      -
                    </button>
                    <input
                      onChange={(e) => {
                        handleHours(item, e.target.value, "mentoring_hours");
                      }}
                      value={item?.mentoring_hours || 0}
                      className="attendance-input-hours"
                      type="number"
                    />
                    <button
                      onClick={() => {
                        let mentoringHrs = item?.mentoring_hours || 0;
                        mentoringHrs = mentoringHrs + 1;
                        if (mentoringHrs > -1) {
                          handleHours(item, mentoringHrs, "mentoring_hours");
                        }
                      }}
                      type="button"
                      className="attendance-input-btn attendance-input-inc"
                    >
                      +
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}

          {/* {STUDENT_DATA.map(item => {
                    return <tr>
                        <td style={{ width: 170 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div
                                    className="img-container"
                                // style={{ margin: "0 auto" }}
                                >
                                    <img src={UserImg} style={{ width: 45, height: 45 }} />
                                </div>
                                <div className="user-details">
                                    <Link className="link-text" to="#">{item.name}</Link>
                                    <div className="user-id">{item.id}</div>
                                </div>
                            </div>
                        </td>
                        <td style={{ width: 80 }}>
                            <div onClick={() => {

                            }} className={`attendance-icon ${item.attendance_status === 'Present' && 'attendance-present'}`}>
                                {item.attendance_status === 'Present' && <FontAwesomeIcon icon={faCheck} />}
                            </div>
                        </td>
                        <td style={{ width: 80 }}>
                            <div onClick={() => {

                            }} className={`attendance-icon ${item.attendance_status === 'Tardy' && 'attendance-late'}`}>
                                {item.attendance_status === 'Tardy' && <FontAwesomeIcon icon={faMinus} />}
                            </div>
                        </td>
                        <td style={{ width: 80 }}>
                            <div className={`attendance-icon ${item.attendance_status === 'Absent' && 'attendance-absent'}`}>
                                {item.attendance_status === 'Absent' && <FontAwesomeIcon icon={faTimes} />}
                            </div>
                        </td>
                        <td style={{ width: 130 }}>
                            <div>
                                <button type="button" className="attendance-input-btn attendance-input-dec">-</button>
                                <input className="attendance-input-hours" type="number" />
                                <button type="button" className="attendance-input-btn attendance-input-inc">+</button>
                            </div>
                        </td>
                        <td style={{ width: 130 }}>
                            <div>
                                <button className="attendance-input-btn attendance-input-dec">-</button>
                                <input className="attendance-input-hours" type="number" />
                                <button className="attendance-input-btn attendance-input-inc">+</button>
                            </div>
                        </td>
                    </tr>
                })} */}
          {/* <tr>
                    <td>Alex English</td>
                    <td>
                        <div className="attendance-icon attendance-present">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon">
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon">
                        </div>
                    </td>
                    <td>
                        <div>
                            <button type="button" className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button type="button" className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                    <td>
                        <div>
                            <button className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                </tr> */}
          {/* <tr>
                    <td>Ezra Miller</td>
                    <td>
                        <div className="attendance-icon">
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon attendance-late">
                            <FontAwesomeIcon icon={faMinus} />
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon">
                        </div>
                    </td>
                    <td>
                        <div>
                            <button type="button" className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button type="button" className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                    <td>
                        <div>
                            <button className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                </tr> */}

          {/* <tr>
                    <td>Eric O'Brian</td>
                    <td>
                        <div className="attendance-icon">
         
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon">
   
                        </div>
                    </td>
                    <td>
                        <div className="attendance-icon attendance-absent">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </td>
                    <td>
                        <div>
                            <button type="button" className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button type="button" className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                    <td>
                        <div>
                            <button className="attendance-input-btn attendance-input-dec">-</button>
                            <input className="attendance-input-hours" type="number" />
                            <button className="attendance-input-btn attendance-input-inc">+</button>
                        </div>
                    </td>
                </tr> */}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 12,
        }}
      >
        <select
          value={pagination.limit}
          onChange={handlePageLimit}
          placeholder="Groups"
          className="input-pagination"
          style={{ width: 130 }}
        >
          {PAGINATION.map((page) => (
            <option value={page}>{page} per page</option>
          ))}
        </select>
        <div className="btn-view-page-container" style={{ marginLeft: 12 }}>
          <span style={{ paddingLeft: 5, fontSize: 14 }}>
            {pagination.page} of {pagination.total}
          </span>
          <button
            type="button"
            disabled={!pagination.offset}
            onClick={handlePagination("prev")}
            style={{ border: "none" }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            disabled={pagination.page === pagination.total}
            onClick={handlePagination("next")}
            style={{ border: "none" }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      <div
        style={{ marginTop: 24, display: "flex", justifyContent: "flex-end" }}
      >
        <button onClick={() => {
          if (handleSave) {
            handleSave()
          }
        }} className="btn-save"
        style={{ width:"100px" }}
          type="button">
          Save
        </button>
        {` `}
        {/* <button
          onClick={() => {
            setIsEditMode(false);
          }}
          className="btn-cancel"
          type="button"
        >
          Cancel
        </button> */}
        <button
     
          className="btn-cancel"
          type="button"
        >
          <Link
            style={{
              textDecoration: 'none'
            }}
            to={`/dashboard/attendance/newevents?vendorId=${vendorId}`}
          >
             <span style={{ color: 'black'}}>Cancel</span>
            {` `}
          </Link>
        </button>
      </div>

      <ModalImport
        openModal={openImportModal}
        onCloseModal={() => setOpenImportModal(false)}
        onSave={handleImportAttendanceData}
      />
    </EventAttendeeListStyled>
  );
};

export default EventAttendeeList;
