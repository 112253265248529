import React from "react";
import styled from "styled-components";

import GridViewIcon from "../constants/icons/gridview.svg";
import BarsIcon from "../constants/icons/bars.svg";

const ViewToggleButtonStyled = styled.div`
  display: inline-block;
  height: 35px;

  .btn-view-toggle-container button {
    background-color: white;
    border: 1px solid #dedede;
    height: 36px;
    // margin-top: 10px;
    position: relative;
    // top: 3px;
  }

  .btn-view-toggle-container button:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .btn-view-toggle-container button:nth-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .view-toggle-selected {
    background-color: #fc9a3b !important;
  }
`;

const ViewToggleButton = ({ setViewMode, viewMode }) => {
  return (
    <ViewToggleButtonStyled>
      <div
        className="btn-view-toggle-container"
        style={{ display: "flex", flexDirection: "row", marginLeft: 4 }}
      >
        <button
          type="button"
          className={`btn-toggle ${
            viewMode === "grid" && "view-toggle-selected"
          }`}
          onClick={() => {
            setViewMode("grid");
          }}
        >
          <img
            src={GridViewIcon}
            alt="grid"
            style={{ width: 14, height: 14 }}
          />
        </button>
        <button
          type="button"
          className={`btn-toggle ${
            viewMode === "list" && "view-toggle-selected"
          }`}
          onClick={() => {
            setViewMode("list");
          }}
        >
          <img src={BarsIcon} alt="grid" style={{ width: 14, height: 14 }} />
        </button>
      </div>
    </ViewToggleButtonStyled>
  );
};

export default ViewToggleButton;
