import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { useDispatch } from "react-redux";
import Layout from "./helpers/Layout";
import Loadable from "react-loadable";
import Loading from "./helpers/Loading.js";
import { requestUserTypes } from "./redux/actions/UserTypes";
import SocialLoginLanding from "./helpers/SocialLogin.js";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import AsyncEventAttendee from "./components/Dashboard/Attendance/eventattendee";

const AsycDashboard = Loadable({
  loader: () => import("./components/Dashboard/"),
  loading: Loading,
});
const AsyncDashBoardHome = Loadable({
  loader: () => import("./components/Dashboard/Home"),
  loading: Loading,
});
const AsyncDashboardMyCalendars = Loadable({
  loader: () => import("./components/Dashboard/MyCalendars"),
  loading: Loading,
});
const AyncDashboardMyContactsPublic = Loadable({
  loader: () => import("./components/Dashboard/MyCalendars/publicCalendar"),
  loading: Loading,
});
const AsyncDashboardMyEvents = Loadable({
  loader: () => import("./components/Dashboard/MyEvents"),
  loading: Loading,
});
const AsyncDashboardMyProfle = Loadable({
  loader: () => import("./components/Dashboard/MyProfile"),
  loading: Loading,
});
const AyncDashboardMyContacts = Loadable({
  loader: () => import("./components/Dashboard/MyContact"),
  loading: Loading,
});
const AsyncAuth = Loadable({
  loader: () => import("./components/Auth"),
  loading: Loading,
});
const AsyncLogin = Loadable({
  loader: () => import("./components/Auth/Login"),
  loading: Loading,
});
const AsyncCreateUser = Loadable({
  loader: () => import("./components/Auth/Create"),
  loading: Loading,
});
const AsyncForgotPassword = Loadable({
  loader: () => import("./components/Auth/ForgotPassword"),
  loading: Loading,
});
const AsyncProfile = Loadable({
  loader: () => import("./components/UserInfo/Profile"),
  loading: Loading,
});
const AsyncApplicationStatus = Loadable({
  loader: () => import("./components/Dashboard/Application"),
  loading: Loading,
});
const AsyncArchivedApplication = Loadable({
  loader: () => import("./components/Dashboard/ArchivedApplication"),
  loading: Loading,
});
const AsyncApplicationForm = Loadable({
  loader: () => import("./components/Dashboard/ApplicationForm"),
  loading: Loading,
});
const AsyncChildInformationView = Loadable({
  loader: () => import("./components/Dashboard/Application/child"),
  loading: Loading,
});
const AsyncParentInformationView = Loadable({
  loader: () => import("./components/Dashboard/Application/parent"),
  loading: Loading,
});
const AsyncClassListInformationView = Loadable({
  loader: () => import("./components/Dashboard/Application/class"),
  loading: Loading,
});
const AsyncMyApplication = Loadable({
  loader: () => import("./components/Dashboard/MyApplication"),
  loading: Loading,
});

const AsyncAuditTrail = Loadable({
  loader: () => import("./components/Dashboard/AuditTrail"),
  loading: Loading,
});

const AsyncBCCalendar = Loadable({
  loader: () => import("./components/Dashboard/BCCalendar"),
  loading: Loading,
});

const AsyncManageAdmin = Loadable({
  loader: () => import("./components/Dashboard/Admin"),
  loading: Loading,
});

const AsyncDaycareApplicationForm = Loadable({
  loader: () => import("./components/Dashboard/DaycareApplicationForm"),
  loading: Loading,
});

const AsynBuilder = Loadable({
  loader: () => import("./components/Dashboard/Builders"),
  loading: Loading,
});

const AsyncForm = Loadable({
  loader: () => import("./components/Dashboard/Builders/Form"),
  loading: Loading,
});

const AsyncForms = Loadable({
  loader: () => import("./components/Dashboard/Forms"),
  loading: Loading,
});

const AsyncAttendance = Loadable({
  loader: () => import("./components/Dashboard/Attendance"),
  loading: Loading,
});

const AsyncAttendanceList = Loadable({
  loader: () => import("./components/Dashboard/Attendance/list"),
  loading: Loading,
});

const AsyncAttendanceSummary = Loadable({
  loader: () => import("./components/Dashboard/Attendance/view"),
  loading: Loading,
});

const AsyncAttendanceEvents = Loadable({
  loader: () => import("./components/Dashboard/Attendance/events"),
  loading: Loading,
});

const AsyncEventCreate = Loadable({
  loader: () => import("./components/Dashboard/Attendance/events/event_create"),
  loading: Loading,
});

// const AsyncEventAttendee = Loadable({
//   loader: () => import("./components/Dashboard/Attendance/eventattendee"),
//   loading: Loading,
// });

const AsyncGradesList = Loadable({
  loader: () => import("./components/Dashboard/Grades/List"),
  loading: Loading,
});

const AsyncGradeIndividual = Loadable({
  loader: () => import("./components/Dashboard/Grades/Individual"),
  loading: Loading,
});

const AsyncGradeIndividualProfile = Loadable({
  loader: () => import("./components/Dashboard/Grades/Individual/Profile"),
  loading: Loading,
});

const AsyncGradeTestInput = Loadable({
  loader: () => import("./components/Dashboard/Grades/TestInput"),
  loading: Loading,
});

const AsyncMyMetrics = Loadable({
  loader: () => import("./components/Dashboard/Metrics"),
  loading: Loading,
});

const AsyncDisplayCalendar = Loadable({
  loader: () => import("./components/Dashboard/BCDisplayCalendar"),
  loading: Loading,
});

const AsyncParentContacts = Loadable({
  loader: () => import("./components/Dashboard/ParentContactList"),
  loading: Loading,
});

const AsyncNewEvents = Loadable({
  loader: () => import("./components/Dashboard/Attendance/events/new_event.js"),
  loading: Loading,
});

const AsyncNewCalendar = Loadable({
  loader: () => import("./components/Dashboard/NewCalendar"),
  loading: Loading,
});

const AsyncImportCSV = Loadable({
  loader: () => import("./components/Dashboard/Attendance/csv"),
  loading: Loading,
});

const AsynAttendanceLink = Loadable({
  loader: () => import("./components/Dashboard/Attendance/attendancelink"),
  loading: Loading,
});

const AsyncAttendanceParents = Loadable({
  loader: () => import("./components/Dashboard/Attendance/parents"),
  loading: Loading,
});

const AsyncChildAttendance = Loadable({
  loader: () =>
    import("./components/Dashboard/Attendance/parents/children_attendance.js"),
  loading: Loading,
});

const AsyncChildRecords = Loadable({
  loader: () =>
    import("./components/Dashboard/Attendance/parents/child_record.js"),
  loading: Loading,
});

const AsyncLandingPage = Loadable({
  loader: () => {
    // if(window.innerWidth <= 768) {
    //   return import('./components/LandingPage/Pages/mobile/home/index.js');
    // }
    return import("./components/LandingPage");
    // return import('./components/LandingPage/Pages/tablet/home/index.js');
  },
  loading: Loading,
});

const AsyncUserattendance = Loadable({
  loader: () => import("./components/UserAttendance"),
  loading: Loading,
});

const AsyncUserGrades = Loadable({
  loader: () => import("./components/UserGrade"),
  loading: Loading,
});

const AsyncAssessmentForm = Loadable({
  loader: () => import("./components/AssessmentForm"),
  loading: Loading,
});

const AsyncParentHome = Loadable({
  loader: () => import("./components/Dashboard/Parents/index"),
  loading: Loading,
});

const AsyncNewApplication = Loadable({
  loader: () => import("./components/Dashboard/Application/new_application.js"),
  loading: Loading,
});

const AsyncParentAttendance = Loadable({
  loader: () => import("./components/Dashboard/Parents/attendance"),
  loading: Loading,
});

const AsyncParentAttendanceDetails = Loadable({
  loader: () => import("./components/Dashboard/Parents/attendance_details"),
  loading: Loading,
});

const AsyncNewCreateEvents = Loadable({
  loader: () =>
    import("./components/Dashboard/Attendance/events/new_event_create.js"),
  loading: Loading,
});

const AsyncAttendaceCreate = Loadable({
  loader: () =>
    import(
      "./components/Dashboard/Attendance/add_attendance/new_create_attendance.js"
    ),
  loading: Loading,
});

const AsyncUserGroupsV2 = Loadable({
  loader: () => import("./components/Dashboard/MyGroupsV2/index.js"),
  loading: Loading,
});

const AsyncMaintenance = Loadable({
  loader: () => import("./components/Auth/Maintenance/index.js"),
  loading: Loading,
});

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestUserTypes());
  }, []);
  return (
    <>
      <Layout>
        <div data-testid="app">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router>
              <AsyncUserattendance path="/event/:event_id/attendance" />
              <AsyncUserGrades path="/user/grades" />
              <AyncDashboardMyContactsPublic path="/mycalendars/public/:id" />
              <AsyncAuth path="/">
                <AsyncLandingPage path="/" default />
                <AsyncLogin path="login" />
                <AsyncCreateUser path="auth/create" />
                <AsyncForgotPassword path="auth/forgot-password" />
                {/* <AsyncMaintenance path="maintenance" /> */}
              </AsyncAuth>
              <AsycDashboard path="/dashboard">
                <AsyncDashBoardHome default />
                <AsyncDashboardMyCalendars path="mycalendars" />
                <AsyncDashboardMyEvents path="myevents" />
                <AsyncDashboardMyProfle path="myprofile" />
                <AyncDashboardMyContacts path="mycontacts" />
                <AsyncUserGroupsV2 path="newgroups" />
                <AsyncProfile path="createprofile" />
                <AsyncApplicationStatus path="application" />
                <AsyncChildInformationView path="menteeprofile/:id" />
                <AsyncParentInformationView path="parentprofile/:id" />
                <AsyncArchivedApplication path="archived" />
                <AsyncClassListInformationView path="class/:form_type/:form_id" />
                <AsyncMyApplication path="myapplication" />
                <AsyncParentContacts path="parentcontacts" />
                <AsyncAuditTrail path="audittrail" />
                <AsyncBCCalendar path="bccalendar" />
                <AsyncManageAdmin path="admin" />
                <AsynBuilder path="builder/:form_id/:type" />
                <AsynBuilder path="builder" />
                <AsyncForms path="forms" />
                <AsyncAttendance path="studentdata" />
                <AsyncAttendanceList path="attendance/:vendor_id/:name" />
                <AsyncAttendanceEvents path="attendance/events" />
                <AsyncEventCreate path="attendance/events/create" />
                <AsyncEventAttendee path="eventattendance/:event_id" />
                <AsyncAttendanceSummary path="attendance/view/:app_group_id" />
                <AsyncGradeIndividualProfile path="grades/profile/:child_id" />
                <AsyncGradeIndividual path="grades/individual/:child_id" />
                <AsyncGradeTestInput path="grades/input/:child_id" />
                <AsyncGradeTestInput path="grades/input" />
                <AsyncGradesList path="grades" />
                <AsyncMyMetrics path="metrics" />
                <AsyncDisplayCalendar path="bcdisplaycalendar" />
                <AsyncNewEvents path="attendance/newevents" />
                <AsyncNewCalendar path="newcalendar" />
                <AsyncImportCSV path="attendance/importcsv" />
                <AsynAttendanceLink path="attendance/link" />
                <AsyncAttendanceParents path="attendance/parents" />
                <AsyncChildAttendance path="attendance/child" />
                <AsyncChildRecords path="attendance/childrecord" />
                <AsyncParentHome path="parent" />
                <AsyncNewApplication path="new_application" />
                <AsyncParentAttendance path="parent/attendance" />
                <AsyncParentAttendanceDetails path="parent/attendance/:event_id" />
                <AsyncNewCreateEvents path="attendance/newevents/create" />
                <AsyncAttendaceCreate path="attendance/newattendance/create" />
              </AsycDashboard>

              <SocialLoginLanding path="sociallanding" />
              <AsyncApplicationForm path="application/:vendor_id" />
              <AsyncApplicationForm path="application/:vendor_id/lot" />
              <AsyncDaycareApplicationForm path="application/:vendor_id/daycare" />
              <AsyncAssessmentForm path="form/assessment" />
              <AsyncForm path="form/:form_id" />
            </Router>
          </MuiPickersUtilsProvider>
        </div>
      </Layout>
    </>
  );
}
