//auth
export const REQUEST_AUTH = "REQUEST_AUTH";
export const REQUEST_AUTH_COMPLETED = "REQUEST_AUTH_COMPLETED";
export const REQUEST_AUTH_USER_INFO = "REQUEST_AUTH_USER_INFO";
export const REQUEST_AUTH_USER_INFO_COMPLETED =
  "REQUEST_AUTH_USER_INFO_COMPLETED";
export const REQUEST_AUTH_LOGOUT = "REQUEST_AUTH_LOGOUT";
export const REQUEST_AUTH_LOGOUT_COMPLETED = "REQUEST_AUTH_LOGOUT_COMPLETED";
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const REQUEST_CHANGE_PASSWORD_COMPLETED =
  "REQUEST_CHANGE_PASSWORD_COMPLETED";
//calendar
export const REQUEST_ADD_CALENDAR = "REQUEST_ADD_CALENDAR";
export const REQUEST_ADD_CALENDAR_COMPLETED = "REQUEST_ADD_CALENDAR_COMPLETED";
export const REQUEST_EDIT_CALENDAR = "REQUEST_EDIT_CALENDAR";
export const REQUEST_EDIT_CALENDAR_COMPLETED =
  "REQUEST_EDIT_CALENDAR_COMPLETED";
export const REQUEST_DELETE_CALENDAR = "REQUEST_DELETE_CALENDAR";
export const REQUEST_DELETE_CALENDAR_COMPLETED =
  "REQUEST_DELETE_CALENDAR_COMPLETED";
export const REQUEST_GET_CALENDARS = "REQUEST_GET_CALENDARS";
export const REQUEST_GET_CALENDARS_COMPLETED =
  "REQUEST_GET_CALENDARS_COMPLETED";
export const REQUEST_GET_CALENDAR = "REQUEST_GET_CALENDAR";
export const REQUEST_GET_CALENDAR_COMPLETED = "REQUEST_GET_CALENDAR_COMPLETED";
export const REQUEST_CLONE_CALENDAR = "REQUEST_CLONE_CALENDAR";
export const REQUEST_CLONE_CALENDAR_COMPLETED =
  "REQUEST_CLONE_CALENDAR_COMPLETED";
//event
export const REQUEST_EVENTS = "REQUEST_EVENTS";
export const REQUEST_EVENTS_COMPLETED = "REQUEST_EVENTS_COMPLETED";
export const REQUEST_ADD_EVENT = "REQUEST_ADD_EVENT";
export const REQUEST_ADD_EVENT_COMPLETED = "REQUEST_ADD_EVENT_COMPLETED";
export const REQUEST_DELETE_EVENT = "REQUEST_DELETE_EVENT";
export const REQUEST_DELETE_EVENT_COMPLETED = "REQUEST_DELETE_EVENT_COMPLETED";
export const REQUEST_UPDATE_EVENT = "REQUEST_UPDATE_EVENT";
export const REQUEST_UPDATE_EVENT_COMPLETED = "REQUEST_UPDATE_EVENT_COMPLETED";
export const REQUEST_SEARCH_EVENTS = "REQUEST_SEARCH_EVENTS";
export const REQUEST_SEARCH_EVENTS_COMPLETED =
  "REQUEST_SEARCH_EVENTS_COMPLETED";
// add by joemari
export const REQUEST_EVENTS_BY_ID_CHILD = "REQUEST_EVENTS_BY_ID_CHILD";
export const REQUEST_EVENTS_BYID_COMPLETED = "REQUEST_EVENTS_BYID_COMPLETED";
//contact
export const REQUEST_ADD_CONTACT = "REQUEST_ADD_CONTACT";
export const REQUEST_ADD_CONTACT_COMPLETED = "REQUEST_ADD_CONTACT_COMPLETED";
export const REQUEST_UPDATE_CONTACT = "REQUEST_UPDATE_CONTACT";
export const REQUEST_UPDATE_CONTACT_COMPLETED =
  "REQUEST_UPDATE_CONTACT_COMPLETED";
export const REQUEST_REMOVE_CONTACT = "REQUEST_REMOVE_CONTACT";
export const REQUEST_REMOVE_CONTACT_COMPLETED =
  "REQUEST_REMOVE_CONTACT_COMPLETED";
export const REQUEST_USER_CONTACT = "USER_REQUEST_CONTACT";
export const SET_USER_CONTACT_LIST = "SET_USER_CONTACT_LIST";

//group
export const REQUEST_ADD_GROUP = "REQUEST_ADD_GROUP";
export const REQUEST_ADD_GROUP_COMPLETED = "REQUEST_ADD_GROUP_COMPLETED";
export const REQUEST_UPDATE_GROUP = "REQUEST_UPDATE_GROUP";
export const REQUEST_UPDATE_GROUP_COMPLETED = "REQUEST_UPDATE_GROUP_COMPLETED";
export const REQUEST_DELETE_GROUP = "REQUEST_DELETE_GROUP";
export const REQUEST_USER_GROUPS_PROTECTED_ROUTE =
  "REQUEST_USER_GROUPS_PROTECTED_ROUTE";
export const REQUEST_USER_GROUPS_PROTECTED_ROUTE_COMPLETED =
  "REQUEST_USER_GROUPS_PROTECTED_ROUTE_COMPLETED";
//relative
export const REQUEST_ADD_RELATIVE = "REQUEST_ADD_RELATIVE";
export const REQUEST_ADD_RELATIVE_COMPLETED = "REQUEST_ADD_RELATIVE_COMPLETED";

//user
export const REQUEST_ADD_USER = "REQUEST_ADD_USER";
export const REQUEST_ADD_USER_COMPLETED = "REQUEST_ADD_USER_COMPLETED";
export const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
export const REQUEST_UPDATE_USER_COMPLETED = "REQUEST_UPDATE_USER_COMPLETED";
export const REQUEST_CHECK_USER_AND_ADD = "REQUEST_CHECK_USER_AND_ADD";
export const REQUEST_CHECK_USER_AND_ADD_COMPLETED =
  "REQUEST_CHECK_USER_AND_ADD_COMPLETED";
//user types
export const REQUEST_USER_TYPES = "REQUEST_USER_TYPES";
export const REQUEST_USER_TYPES_COMPLETED = "REQUEST_USER_TYPES_COMPLETED";

// status
export const REQUEST_STATUS = "REQUEST_STATUS";
export const REQUEST_STATUS_COMPLETED = "REQUEST_STATUS_COMPLETED";
export const REQUEST_REMOVE_STATUS = "REQUEST_REMOVE_STATUS";
export const REQUEST_REMOVE_STATUS_COMPLETED =
  "REQUEST_REMOVE_STATUS_COMPLETED";

// ADDED BY DENNIS
export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE";
export const REQUEST_SECURITY_QUESTIONS = "REQUEST_SECURITY_QUESTIONS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const REQUEST_UPDATE_USER_PROFILE = "REQUEST_UPDATE_USER_PROFILE";
export const REQUEST_UPDATE_USER_PHOTO = "REQUEST_UPDATE_USER_PHOTO";

// GROUPS
export const REQUEST_USER_GROUPS = "REQUEST_USER_GROUPS";
export const SET_USER_GROUPS = "SET_USER_GROUPS";

export const REQUEST_MEMBERS = "REQUEST_MEMBERS";
export const SET_MEMBER_LIST = "SET_MEMBER_LIST";

//FAMILY MEMBERS
export const REQUEST_FAMILY_MEMBERS = "REQUEST_FAMILY_MEMBERS";
export const REQUEST_FAMILY_MEMBERS_COMPLETED =
  "REQUEST_FAMILY_MEMBERS_COMPLETED";

export const SET_CONTACT_LOADING = "SET_CONTACT_LOADING";
export const SET_GROUP_LOADING = "SET_GROUP_LOADING";
export const SET_GROUP_MEMBER_LOADING = "SET_GROUP_MEMBER_LOADING";
export const SET_EVENT_LOADING = "SET_EVENT_LOADING";
export const SET_PROFILE_LOADING = "SET_PROFILE_LOADING";
export const SET_APPLICATION_LOADING = "SET_APPLICATION_LOADING";
export const SET_FORM_SETTINGS_LOADING = "SET_FORM_SETTINGS_LOADING";
export const SET_USER_APPLICATION_LOADING = "SET_USER_APPLICATION_LOADING";

// ADDED BY JEROME
// GRADES
export const REQUEST_GRADES = "REGUEST_GRADES";
export const REQUEST_GRADES_COMPLETED = "REQUEST_GRADES_COMPLETED";

// VENDORS
export const REQUEST_VENDOR = "REGUEST_VENDOR";
export const REQUEST_VENDOR_COMPLETED = "REQUEST_VENDOR_COMPLETED";
export const REQUEST_UPDATE_VENDOR = "REQUEST_UPDATE_VENDOR";
export const REQUEST_UPDATE_VENDOR_COMPLETED =
  "REQUEST_UPDATE_VENDOR_COMPLETED";
export const REQUEST_USER_VENDOR_FORMS = "REQUEST_USER_VENDOR_FORMS";
export const REQUEST_USER_VENDOR_FORMS_COMPLETED =
  "REQUEST_USER_VENDOR_FORMS_COMPLETED";

//APPLICATION
export const REQUEST_ADD_APPLICATION = "REQUEST_ADD_APPLICATION";
export const REQUEST_ADD_APPLICATION_COMPLETED =
  "REQUEST_ADD_APPLICATION_COMPLETED";
export const REQUEST_APPLICATION_COMPLETED = "REQUEST_APPLICATION_COMPLETED";
export const REQUEST_GET_APPLICATION = "REQUEST_GET_APPLICATION";
export const REQUEST_GET_APPLICATION_COMPLETED =
  "REQUEST_GET_APPLICATION_COMPLETED";
export const REQUEST_USER_APPLICATIONS = "REQUEST_USER_APPLICATIONS";
export const SET_USER_APPLICATIONS = "SET_USER_APPLICATIONS";
export const REQUEST_UPDATE_APPLICATION = "REQUEST_UPDATE_APPLICATION";
export const REQUEST_UPDATE_APPLICATION_COMPLETED =
  "REQUEST_UPDATE_APPLICATION_COMPLETED";
export const REQUEST_ARCHIVED_APPLICATION = "REQUEST_ARCHIVED_APPLICATION";
export const REQUEST_ARCHIVED_APPLICATION_COMPLETED =
  "REQUEST_ARCHIVED_APPLICATION_COMPLETED";

export const REQUEST_UNARCHIVED_APPLICATION = "REQUEST_UNARCHIVED_APPLICATION";
export const REQUEST_UNARCHIVED_APPLICATION_COMPLETED =
  "REQUEST_UNARCHIVED_APPLICATION_COMPLETED";

export const REQUEST_GET_ARCHIVED_APPLICATION =
  "REQUEST_GET_ARCHIVED_APPLICATION";
export const REQUEST_GET_ARCHIVED_APPLICATION_COMPLETED =
  "REQUEST_GET_ARCHIVED_APPLICATION_COMPLETED";
export const REQUEST_GET_APPLICATION_ID = "REQUEST_GET_APPLICATION_ID";
export const REQUEST_GET_APPLICATION_ID_COMPLETED =
  "REQUEST_GET_APPLICATION_ID_COMPLETED";

export const REQUEST_SIGNUP_FAILED = "REQUEST_SIGNUP_FAILED";

export const REQUEST_ADD_VENDOR_APP_GROUP = "REQUEST_ADD_VENDOR_APP_GROUP";

export const REQUEST_SAVE_APPLICATION = "REQUEST_SAVE_APPLICATION";
export const REQUEST_SAVE_APPLICATION_COMPLETED =
  "REQUEST_SAVE_APPLICATION_COMPLETED";

export const REQUEST_GET_APPLICATION_USER_ID =
  "REQUEST_GET_APPLICATION_USER_ID";
export const REQUEST_GET_APPLICATION_USER_ID_COMPLETED =
  "REQUEST_GET_APPLICATION_USER_ID_COMPLETED";

export const REQUEST_EDIT_VENDOR_APP_GROUP = "REQUEST_EDIT_VENDOR_APP_GROUP";
export const REQUEST_DELETE_VENDOR_APP_GROUP =
  "REQUEST_DELETE_VENDOR_APP_GROUP";

export const REQUEST_GET_APPLICATION_HISTORY =
  "REQUEST_GET_APPLICATION_HISTORY";
export const REQUEST_GET_APPLICATION_HISTORY_COMPLETE =
  "REQUEST_GET_APPLICATION_HISTORY_COMPLETE";

export const REQUEST_GET_USER_APPLICATION_HISTORY =
  "REQUEST_GET_USER_APPLICATION_HISTORY";
export const REQUEST_GET_USER_APPLICATION_HISTORY_COMPLETE =
  "REQUEST_GET_USER_APPLICATION_HISTORY_COMPLETE";

export const REQUEST_VENDOR_BY_ID2 = "REQUEST_VENDOR_BY_ID2";
export const REQUEST_VENDOR_BY_ID = "REQUEST_VENDOR_BY_ID";

export const REQUEST_CREATE_GROUP_REMINDER = "REQUEST_CREATE_GROUP_REMINDER";
export const REQUEST_CREATE_GROUP_REMINDER_COMPLETED =
  "REQUEST_CREATE_GROUP_REMINDER_COMPLETED";

export const REQUEST_GET_VENDOR_REMINDER = "REQUEST_GET_VENDOR_REMINDER";
export const REQUEST_GET_VENDOR_REMINDER_COMPLETED =
  "REQUEST_GET_VENDOR_REMINDER_COMPLETED";

export const REQUEST_GET_VENDOR_ADMINS = "REQUEST_GET_VENDOR_ADMINS";

export const REQUEST_GET_VENDOR_ADMINS_COMPLETED =
  "REQUEST_GET_VENDOR_ADMINS_COMPLETED";

export const REQUEST_ADD_ADMIN = "REQUEST_ADD_ADMIN";

export const REQUEST_UPDATE_ADMIN = "REQUEST_UPDATE_ADMIN";

export const REQUEST_DELETE_ADMINS = "REQUEST_DELETE_ADMINS";

export const SET_ADD_ADMIN_LOADING = "SET_ADD_ADMIN_LOADING";

export const SET_DELETE_ADMIN_LOADING = "SET_DELETE_ADMIN_LOADING";

export const REQUEST_GET_FORM_APP_GROUP = "REQUEST_GET_FORM_APP_GROUP";

export const REQUEST_GET_FORM_APP_GROUP_COMPLETED =
  "REQUEST_GET_FORM_APP_GROUP_COMPLETED";

// Added by Jeff
// Start Form Builder
export const REQUEST_GET_FORMS = "REQUEST_GET_FORMS";
export const REQUEST_GET_FORMS_COMPLETED = "REQUEST_GET_FORMS_COMPLETED";

export const REQUEST_ADD_FORM = "REQUEST_ADD_FORM";
export const REQUEST_ADD_FORM_COMPLETED = "REQUEST_ADD_FORM_COMPLETED";

export const REQUEST_UPDATE_FORM = "REQUEST_UPDATE_FORM";
export const REQUEST_UPDATE_FORM_COMPLETED = "REQUEST_UPDATE_FORM_COMPLETED";

export const REQUEST_DELETE_FORM = "REQUEST_DELETE_FORM";
export const REQUEST_DELETE_FORM_COMPLETED = "REQUEST_DELETE_FORM_COMPLETED";

export const REQUEST_GET_FORM_ID = "REQUEST_GET_FORM_ID";
export const REQUEST_GET_FORM_ID_COMPLETED = "REQUEST_GET_FORM_ID_COMPLETED";

export const REQUEST_SUBMIT_FORM = "REQUEST_SUBMIT_FORM";
export const REQUEST_SUBMIT_FORM_COMPLETED = "REQUEST_SUBMIT_FORM_COMPLETED";

export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const CLEAR_FORM_MESSAGE = "CLEAR_FORM_MESSAGE";

export const SET_ADD_FORM_LOADING = "SET_ADD_FORM_LOADING";
export const SET_UPDATE_FORM_LOADING = "SET_UPDATE_FORM_LOADING";
export const SET_GET_FORM_LOADING = "SET_GET_FORM_LOADING";
export const SET_DELETE_FORM_LOADING = "SET_DELETE_FORM_LOADING";
export const SET_SUBMIT_FORM_LOADING = "SET_SUBMIT_FORM_LOADING";

// Custom Applications
export const REQUEST_GET_CUSTOM_APPLICATION = "REQUEST_GET_CUSTOM_APPLICATION";
export const REQUEST_GET_CUSTOM_APPLICATION_COMPLETED =
  "REQUEST_GET_CUSTOM_APPLICATION_COMPLETED";

export const REQUEST_GET_CUSTOM_APPLICATION_BY_ID =
  "REQUEST_GET_CUSTOM_APPLICATION_BY_ID";
export const REQUEST_GET_CUSTOM_APPLICATION_BY_ID_COMPLETED =
  "REQUEST_GET_CUSTOM_APPLICATION_BY_ID_COMPLETED";

export const REQUEST_UPDATE_SUBMITTED_FORM = "REQUEST_UPDATE_SUBMITTED_FORM";
export const REQUEST_UPDATE_SUBMITTED_FORM_COMPLETED =
  "REQUEST_UPDATE_SUBMITTED_FORM_COMPLETED";

export const REQUEST_CUSTOM_APPLICATION_HISTORY =
  "REQUEST_CUSTOM_APPLICATION_HISTORY";
export const REQUEST_CUSTOM_APPLICATION_HISTORY_COMPLETED =
  "REQUEST_CUSTOM_APPLICATION_HISTORY_COMPLETED";
// End Form Builder

//attendance
export const REQUEST_ATTENDANCE = "REQUEST_ATTENDANCE";
export const REQUEST_EVENT_ATTENDANCE = "REQUEST_EVENT_ATTENDANCE";
export const REQUEST_UPDATE_ATTENDANCE = "REQUEST_UPDATE_ATTENDANCE";
export const SET_ATTENDANCE_LIST = "SET_ATTENDANCE_LIST";
export const SET_EVENT_ATTENDANCE_LIST = "SET_EVENT_ATTENDANCE_LIST";
export const REQUEST_UPDATE_ATTENDANCE_SUCCESS =
  "REQUEST_UPDATE_ATTENDANCE_SUCCESS";

export const REQUEST_VENDOR_APP_GROUPS = "REQUEST_VENDOR_APP_GROUPS";
export const REQUEST_VENDOR_APP_GROUPS_COMPLETED =
  "REQUEST_VENDOR_APP_GROUPS_COMPLETED";

export const REQUEST_CUSTOM_APPLICATION_BY_VENDOR =
  "REQUEST_CUSTOM_APPLICATION_BY_VENDOR";
export const REQUEST_CUSTOM_APPLICATION_BY_VENDOR_SUCCESS =
  "REQUEST_CUSTOM_APPLICATION_BY_VENDOR_SUCCESS";
export const REQUEST_GET_CUSTOM_APPLICATION_BY_VENDOR_COMPLETED =
  "REQUEST_GET_CUSTOM_APPLICATION_BY_VENDOR_COMPLETED";

export const REQUEST_USER_ATTENDANCE_FILTER_CONFIG =
  "REQUEST_USER_ATTENDANCE_FILTER_CONFIG";
// Grades; Added by Jeff
export const SET_GRADE_LOADING = "SET_GRADE_LOADING";
export const CUMULATIVE_GRADE_BY_APP_GROUP = "CUMULATIVE_GRADE_BY_APP_GROUP";
export const CUMULATIVE_GRADE_BY_APP_GROUP_COMPLETED =
  "CUMULATIVE_GRADE_BY_APP_GROUP_COMPLETED";
export const CUMULATIVE_GRADE_BY_PARENT = "CUMULATIVE_GRADE_BY_PARENT";
export const CUMULATIVE_GRADE_BY_VENDOR = "CUMULATIVE_GRADE_BY_VENDOR";
export const CUMULATIVE_GRADE_BY_VENDOR_COMPLETED =
  "CUMULATIVE_GRADE_BY_VENDOR_COMPLETED";
export const CUMULATIVE_GRADE_BY_USER = "CUMULATIVE_GRADE_BY_USER";
export const CUMULATIVE_GRADE_BY_USER_COMPLETED =
  "CUMULATIVE_GRADE_BY_USER_COMPLETED";
export const ADD_UPDATE_STUDENT_STANDARD_TEST =
  "ADD_UPDATE_STUDENT_STANDARD_TEST";
export const ADD_UPDATE_STUDENT_STANDARD_TEST_COMPLETED =
  "ADD_UPDATE_STUDENT_STANDARD_TEST_COMPLETED";
export const SET_GRADE_STANDARD_LOADING = "SET_GRADE_STANDARD_LOADING";
export const DELETE_STUDENT_STANDARDIZED_TEST =
  "DELETE_STUDENT_STANDARDIZED_TEST";
export const DELETE_STUDENT_STANDARDIZED_TEST_COMPLETED =
  "DELETE_STUDENT_STANDARDIZED_TEST_COMPLETED";
export const ADD_UPDATE_STUDENT_CUMULATIVE = "ADD_UPDATE_STUDENT_CUMULATIVE";
export const ADD_UPDATE_STUDENT_CUMULATIVE_COMPLETED =
  "ADD_UPDATE_STUDENT_CUMULATIVE_COMPLETED";
export const SET_GRADE_EDIT_LOADING = "SET_GRADE_EDIT_LOADING";
export const CLEAR_GRADES = "CLEAR_GRADES";
export const ARCHIVE_GROUP = "ARCHIVE_GROUP";
export const ARCHIVE_GROUP_COMPLETED = "ARCHIVE_GROUP_COMPLETED";
export const ADD_ARCHIVE_GROUP = "ADD_ARCHIVE_GROUP";
export const ADD_ARCHIVE_GROUP_COMPLETED = "ADD_ARCHIVE_GROUP_COMPLETED";
export const REMOVE_GROUP_FROM_ARCHIVE = "REMOVE_GROUP_FROM_ARCHIVE";
export const REMOVE_GROUP_FROM_ARCHIVE_COMPLETED =
  "REMOVE_GROUP_FROM_ARCHIVE_COMPLETED";
export const SET_ARCHIVE_LOADING = "SET_ARCHIVE_LOADING";
export const CLEAR_GROUP_ARCHIVE = "CLEAR_GROUP_ARCHIVE";

export const SET_DEFAULT_VENDOR = "SET_DEFAULT_VENDOR";

export const REQUEST_UPDATE_VENDOR_LOGO = "REQUEST_UPDATE_VENDOR_LOGO";
export const REQUEST_CREATE_VENDOR = "REQUEST_CREATE_VENDOR";
export const REQUEST_DUPLICATE_VENDOR_COMPLETED =
  "REQUEST_DUPLICATE_VENDOR_COMPLETED";
export const REQUEST_SELECTED_VENDOR = "REQUEST_SELECTED_VENDOR";
export const REQUEST_SELECTED_VENDOR_COMPLETED =
  "REQUEST_SELECTED_VENDOR_COMPLETED";

export const REQUEST_PARENT_BY_VENDOR = "REQUEST_PARENT_BY_VENDOR";
export const REQUEST_PARENT_PERMISSION_BY_VENDOR =
  "REQUEST_PARENT_PERMISSION_BY_VENDOR";
export const SET_PARENT_LIST_BY_VENDOR = "SET_PARENT_LIST_BY_VENDOR";

export const REQUEST_USER_EVENT_ATTENDANCE = "REQUEST_USER_EVENT_ATTENDANCE";
export const REQUEST_USER_EVENT_ATTENDANCE_COMPLETED =
  "REQUEST_USER_EVENT_ATTENDANCE_COMPLETED";

export const REQUEST_ATTENDANCE_BY_EVENT_ID = "REQUEST_ATTENDANCE_BY_EVENT_ID";

export const SET_CURRENT_EVENT_ATTENDANCE = "SET_CURRENT_EVENT_ATTENDANCE";

export const SET_DEFAULT_VENDOR_FORMS = "SET_DEFAULT_VENDOR_FORMS";

export const REQUEST_CREATE_UPDATE_SUBGROUP = "REQUEST_CREATE_UPDATE_SUBGROUP";
export const REQUEST_DELETE_VENDOR_SUB_GROUP =
  "REQUEST_DELETE_VENDOR_SUB_GROUP";
export const REQUEST_SUB_GROUP_BY_VENDOR = "REQUEST_SUB_GROUP_BY_VENDOR";
export const SET_VENDOR_SUB_GROUPS = "SET_VENDOR_SUB_GROUPS";

export const REQUEST_CREATE_UPDATE_GROUP_WITH_SUB_GROUP =
  "REQUEST_CREATE_UPDATE_GROUP_WITH_SUB_GROUP";


export const REQUEST_UPDATE_MULTIPLE_GROUP = "REQUEST_UPDATE_MULTIPLE_GROUP";